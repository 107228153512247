module.exports={
  "name": "fin-hypergrid",
  "version": "3.3.2",
  "description": "Canvas-based high-performance grid",
  "main": "src/Hypergrid",
  "repository": {
    "type": "git",
    "url": "git://github.com/fin-hypergrid/core.git"
  },
  "author": "Steve Wirts (https://github.com/stevewirts)",
  "contributors": [
    "Jonathan Eiten (https://github.com/joneit)",
    "Dwayne Jones (https://github.com/dwaynekj)",
    "Naveen Michaud-Agrawal (https://github.com/nmichaud)",
    "Hugo Nogueira (https://github.com/hugoeanogueira)"
  ],
  "license": "MIT",
  "readmeFilename": "README.md",
  "gitHead": "",
  "keywords": [
    "spreadsheet",
    "grid"
  ],
  "dependencies": {
    "datasaur-base": "^3.0.0",
    "datasaur-local": "^3.0.0",
    "extend-me": "^2.7.0",
    "finbars": "^2.0.0",
    "inject-stylesheet-template": "^1.0.1",
    "mustache": "^2.3.0",
    "object-iterators": "1.3.0",
    "overrider": "^0",
    "rectangular": "1.0.1",
    "sparse-boolean-array": "1.0.1",
    "svg-themer": "^1.1.2",
    "synonomous": "^2.1.2"
  },
  "devDependencies": {
    "gulp": "^3.9.0",
    "gulp-concat": "^2.6.0",
    "gulp-each": "^0.1.1",
    "gulp-eslint": "^4.0.2",
    "gulp-footer": "^1.1.1",
    "gulp-header": "^1.8.2",
    "gulp-imagine-64": "^2.0.1",
    "gulp-load-plugins": "^1.1.0",
    "gulp-mocha": "^6.0.0",
    "run-sequence": "^1.1.4"
  }
}
